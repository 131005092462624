import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, firstValueFrom, lastValueFrom, BehaviorSubject } from 'rxjs';
import { ApiConstants } from '../constants/api-constants';
import { SocketService } from './socket.service';
import { ApiResponse } from '../../models/api-response.model';
import { Alarm } from '../../models/alarm.model';
import { UserSessionStateService } from './user-session-state.service';
import { AuthenticationService } from './authentication.service';
import { BranchService } from './branch.service';
import { WEB_SOCKET_EVENTS } from '../enums/web-socket-events';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../app/analytical-dashboard/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { VideoStreamService } from './video-stream.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from './../app-config.service';
import { WorkflowService } from './workflow.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChatService } from './chat.service';
import { AlarmStatus } from '../enums/alarm-status.enum';
import { InsightsService } from './insights.service';

@Injectable({
	providedIn: 'root'
})
export class AlarmService {
	alarmStatus: any;
	public onAlarmSelectedListener = new Subject<Alarm>();
	public onAlarmListChangedListener = new Subject<Alarm[]>();
	alarmList: Alarm[];
	escalationMap: { seconds: number, alarmId: string }[] = [];
	public selectedAlarm: Alarm;
	socketType: any;
	alarmHistoryChangeListener = new Subject<Alarm[]>();
	changeAlarmSelectedState = new Subject<any>();
	newAlarmAddedNotification = new Subject<any>();
	socketTypeSubject = new Subject<any>();
	alarmHistory: any = [];
	alarmCount: Number;
	newNotification = new Subject<any>();
	public allowAudio: boolean = false;
	ricStatusSub = new Subject<any>();
	chatBotSelectedSubject = new Subject<boolean>();
	private isWorkflowHidden = new BehaviorSubject<boolean>(false);
	insightsStartDate: number;
	insightsEndDate: number;

	constructor(
		private http: HttpClient, private socketService: SocketService, private stateService: UserSessionStateService, private authenticationService: AuthenticationService, private branchService: BranchService, public dialog: MatDialog, public videoStreamService: VideoStreamService, public translate: TranslateService, public appConfigService: AppConfigService, public workflowService: WorkflowService, public _snackBar: MatSnackBar, public chatService: ChatService, public insightsService: InsightsService
	) {
	}

	startListeningToNewAlarms() {
		this.socketService.initSocket();
		const socketId = localStorage.getItem('socketId');
		if (!socketId) {
			// socketId is missing hence listening to new alarm is ignored
			return;
		}
		// [AlarmService] User socket id is:" + socketId
		const eventNotification = this.socketService.onEvent<any>(socketId);
		if (eventNotification) {
			eventNotification.subscribe((socketEvent) => {
				const data = socketEvent.data;
				this.socketType = socketEvent.type;
				// this.socketTypeSubject.next(this.socketType);
				console.log(this.socketType);
				// Received web socket event", socketEvent
				switch (socketEvent.type) {
					case WEB_SOCKET_EVENTS.NEW_ALARM:
						this.onNewAlarmEvent(data);
						break;
					case WEB_SOCKET_EVENTS.ESCALATE:
						this.onEscalateEvent(data);
						break;
					case WEB_SOCKET_EVENTS.ASSIGN_ALARM:
						this.onAssignAlarmEvent(data);
						break;
					case WEB_SOCKET_EVENTS.DENY_ALARM:
						this.onDenyAlarmEvent(data);
						break;
					case WEB_SOCKET_EVENTS.REMOVE_ALARM:
						const alarmData = data.alarm ? data.alarm : data;
						if (!this.isWorkflowHidden.getValue()) {
							this.onRemoveAlarmEvent(alarmData);
						}
						break;
					case WEB_SOCKET_EVENTS.TAKEN_OVER_ALARM:
						if (!this.isWorkflowHidden.getValue()) {
							this.onTakenOverAlarm(data);
						}
						break;
					case WEB_SOCKET_EVENTS.MESSAGE:
						this.onMessage(data);
						break;
					case WEB_SOCKET_EVENTS.REFRESH_SUPER_ROUTES:
						this.refreshSupervisorRoutes(data);
						break;
					case WEB_SOCKET_EVENTS.UPDATE_MAP:
						this.updateMap();
						break;
					case WEB_SOCKET_EVENTS.LOG_OUT:
						this.Logout(data);
						break;
					case WEB_SOCKET_EVENTS.NEW_ACTION_HISTORY_EVENT:
						this.onNewActionHistoryEvent(data);
						break;
					case WEB_SOCKET_EVENTS.CHAT_MESSAGE_NOTIFICATION:
						this.onChatMessageNotification(data);
						break;
					case WEB_SOCKET_EVENTS.CHAT_STARTED:
						this.onNewChatNotification(data);
						break;
					case WEB_SOCKET_EVENTS.NOT_ALLOWED_ACTION:
						this.onNotAllowedAction(data);
						break;
					case WEB_SOCKET_EVENTS.ALARM_STATUS_CHANGE:
						this.changeStatusAlarmNotification(data.alarm);
						break;
					case WEB_SOCKET_EVENTS.RIC_STATUS_CHANGE:
						this.applyRicUpdates(data);
						break;
					case WEB_SOCKET_EVENTS.MULTIPLE_ALARMS_RIC_STATUS_CHANGE:
						this.applyRicUpdates(data);
						break;
					default:
				}
			});
		}
	}

	onMessage(message: any) {
		const title = this.translate.instant('ANALYTICAL_FILTERS.ERROR_TITLE');
		const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
		const cancelText = this.translate.instant('ANALYTICAL_FILTERS.CANCEL_TEXT');
		const typeOfDialogBox = 2;
		const dialogData = new ConfirmDialogModel(title, message, confirmText, cancelText, typeOfDialogBox);
		if (this.videoStreamService.fullScreenVideo) {
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => {
				//
			});
		} else {
			this.dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				disableClose: true
			});
		}
	}

	openSnackBar(message: string, action: string) {
		return this._snackBar.open(message, action, {
			horizontalPosition: 'center',
			verticalPosition: 'top',
			panelClass: 'custom-snack-bar',
		});
	}

	onNewAlarmEvent(alarm: Alarm) {
		// Audible event on receiving new alarm
		if (this.allowAudio === true) {
			let audio = new Audio('../assets/audio/alarm.mp3');
			audio.play();
		}
		// Alarm list update after receiving new alarms
		this.alarmList = this.alarmList && this.alarmList.length > 0 ? this.alarmList : [];
		let alarmExist = false;
		for (let i = 0; i < this.alarmList.length; i++) {
			if (this.alarmList[i].id === alarm.id) {
				this.alarmList[i] = alarm;
				alarmExist = true;
			}
		}

		if (!alarmExist) {
			this.alarmList = [alarm].concat(this.alarmList);
		}
		const selectedAlarmId = this.selectedAlarm ? this.selectedAlarm.id : null;
		this.changeAlarmList(this.alarmList, selectedAlarmId);
		this.newAlarmAddedNotification.next(alarm);
		if (this.alarmList.length > this.appConfigService.appConfig.ALARMS_LIST_LIMIT) {
			this.onRemoveAlarmEvent(this.alarmList[this.alarmList.length - 1]);
		}

		this.updateInsightsDateRange();
	}

	updateInsightsDateRange() {
		this.insightsStartDate = this.insightsService.dateRangeSource.getValue().startDate;
		this.insightsEndDate = this.insightsService.dateRangeSource.getValue().endDate;
		const now = new Date();
		const currentEndDate = new Date(this.insightsEndDate);
		if (currentEndDate.toDateString() === now.toDateString()) {
			this.insightsEndDate = now.getTime() + (60 * 60 * 1000); // Add 1 hour in milliseconds
		}
		this.insightsService.updateDateRange(this.insightsStartDate, this.insightsEndDate);
	}

	getNewAlarmNotification() {
		return this.newAlarmAddedNotification.asObservable();
	}

	onEscalateEvent(alarm: Alarm) {
		this.onNewAlarmEvent(alarm);
	}

	onAssignAlarmEvent(alarm: Alarm) {
		this.onNewAlarmEvent(alarm);
		this.updateMap();
	}

	onDenyAlarmEvent(data: Alarm) {
		// TODO:
	}

	refreshSupervisorRoutes(data: any) {
		if (data === "newAlarm") {
			// this.getUnAttendedAlarmsCount();
			// this.getEscalatedAlarmsCount();

			// Issue 402 Send subscriber to alarms page to update the count
			this.onAlarmListChangedListener.next(this.alarmList);
			this.updateInsightsDateRange();
		} else if (data.data && typeof (data) === 'object' && data.event === "repeatedAlarm") {
			this.onNewAlarmEvent(data.data);
		} else if (data === "updateAlarmStatus") {
			this.updateInsightsDateRange();
		}
	}

	onRemoveAlarmEvent(data: any) {
		this.alarmList = this.alarmList && this.alarmList.length > 0 ? this.alarmList : [];
		if (this.alarmList.length > 0) {
			let selectedAlarmId = this.selectedAlarm ? this.selectedAlarm.id : null;
			let wasSelectedAlarmRemoved = false;

			if (selectedAlarmId === data.id) {
				this.videoStreamService.fullScreenVideo = false;
				selectedAlarmId = null;
				wasSelectedAlarmRemoved = true;
			}

			// Fetch the updated alarm list
			this.fetchAlarmsList(false).then(() => {
				if (!wasSelectedAlarmRemoved && selectedAlarmId) {
					// If the removed alarm wasn't the selected one, try to reselect the previously selected alarm
					const previouslySelectedAlarm = this.alarmList.find(alarm => alarm.id === selectedAlarmId);
					if (previouslySelectedAlarm) {
						this.selectAlarm(previouslySelectedAlarm);
					}
				}
			});
		}
		this.updateInsightsDateRange();
	}

	onTakenOverAlarm(data: any) {
		const title = this.translate.instant('ANALYTICAL_FILTERS.ERROR_TITLE');
		const message = data.msg;
		const confirmText = this.translate.instant('ANALYTICAL_FILTERS.OKAY_TEXT');
		const typeOfDialogBox = 2;
		const dialogData = new ConfirmDialogModel(title, message, confirmText, null, typeOfDialogBox);
		if (this.videoStreamService.fullScreenVideo) {
			let snackBarRef = this.openSnackBar(message, confirmText);
			snackBarRef.afterDismissed().subscribe(() => {
				this.onRemoveAlarmEvent(data.alarm);
			});
		} else {
			const dialogRef = this.dialog.open(ConfirmDialogComponent, {
				data: dialogData,
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(() => {
				this.onRemoveAlarmEvent(data.alarm);
			});
		}
	}

	getAlarmDetails(alarmId: string) {
		return lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS + '/' + alarmId));
	}

	changeAlarmStatus(alarmId: string, status: string) {
		return lastValueFrom(this.http.patch<ApiResponse>(ApiConstants.URL_ALARMS + '/' + alarmId + '/status', { status: status }));
	}

	getAlarms() {
		return lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS));
	}

	async getFilteredAlarms(filter: boolean) {
		const httpParams = new HttpParams().set('filter[all]', filter);
		const options = { params: httpParams };
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS_FILTERS, options));
		return response;
	}

	getShortAlarmIds() {
		return lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SHORT_ALARM_IDS));
	}

	getAlarmHistory(branchId: string) {
		return lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS + '/branches/' + branchId));
	}

	async getEscalatedAlarmsCount() {
		const url = ApiConstants.URL_ESCALATED_ALARMS;
		let res = await lastValueFrom(this.http.get<ApiResponse>(url));
		if (res.success === true) {
			return res.data;
		} else {
			return 0;
		}
	}

	async getUnAttendedAlarmsCount() {
		const url = ApiConstants.URL_UN_ATTENDED_ALARMS;
		let res = await lastValueFrom(this.http.get<ApiResponse>(url));
		if (res.success === true) {
			return res.data;
		} else {
			return 0;
		}
	}

	getCurrentBranchId() {
		return this.selectedAlarm.branch.id;
	}

	selectAlarm(alarm: Alarm) {
		if (alarm) {
			this.checkAssigned(alarm.id);
		}

		this.selectedAlarm = alarm;
		this.onAlarmSelectedListener.next(alarm);

		setTimeout(() =>
			this.playVideoFeeds(alarm)
			, 0);
	}

	playVideoFeeds(alarm: Alarm) {
		const branchId = alarm && alarm.branch ? alarm.branch.id : null;
		if (this.isContainZone(alarm)) {
			const sources = alarm?.sensor?.zone?.sources || alarm?.sources;
			const sourcesArray = Array.isArray(sources) ? sources : sources ? [sources] : [];
			this.videoStreamService.playVideoFeedsFromZones(sourcesArray);
		} else {
			this.videoStreamService.playVideoFeedsFromBranch(branchId);
		}
	}

	isThermalAlarm(alarm: Alarm) {
		let status = false;
		const video = alarm && alarm.aiVideo;
		if (typeof video == "undefined") {
			status = false;
		}
		else if (alarm !== null) {
			status = true;
		}
		else {
			status = false;
		}
		return status;
	}

	isContainZone(alarm: Alarm) {
		let status = false;
		const zone = alarm && (alarm.zone || alarm?.sensor?.zone);
		if (typeof zone == "undefined") {
			status = false;
		}
		else if (alarm !== null) {
			status = true;
		}
		else {
			status = false;
		}
		return status;
	}

	getOnAlarmSelectedListener() {
		return this.onAlarmSelectedListener.asObservable();
	}

	async getAssetData(branchID: string) {
		const url = ApiConstants.ASSETS_URL + '?branchID=' + branchID;
		const response = await lastValueFrom(this.http.get<ApiResponse>(url));
		return response.success ? response.data : [];
	}

	async escalateAlarm(alarm: Alarm) {
		try {
			await this.changeAlarmStatus(alarm.id, this.translate.instant('ALARM_STATUS.ESCALATED'));
		} catch (err) { }
	}

	getOnAlarmListChangedListener() {
		return this.onAlarmListChangedListener.asObservable();
	}

	changeAlarmList(alarmList: Alarm[], selectedAlarmId?: string) {
		this.alarmList = alarmList;
		this.stateService.createAlarmEntries(this.alarmList);
		this.onAlarmListChangedListener.next(this.alarmList);
		if (this.alarmList && this.alarmList.length > 0) {
			let isSelectedAlarmIdPresent = false;
			if (selectedAlarmId) {
				this.alarmList.forEach((alarm: Alarm) => {
					if (alarm.id === selectedAlarmId) {
						isSelectedAlarmIdPresent = true;
						this.selectAlarm(alarm);
					}
				});
			}
			if (!isSelectedAlarmIdPresent) {
				this.selectAlarm(this.alarmList[0]);
			}
		} else {
			this.selectAlarm(null);
			this.videoStreamService.setEmptyVideoSources();
		}
	}

	async fetchAlarmsList(filter?: boolean) {
		this.isWorkflowHidden.next(filter);
		const response: ApiResponse = await this.getFilteredAlarms(filter);
		if (response && response.data && response.data.length !== 0) {
			let selectedAlarmId = response.data[0].id;
			this.changeAlarmList(response.data, selectedAlarmId);
		} else {
			this.changeAlarmList([]);
		}
	}

	getIsWorkflowHidden() {
		return this.isWorkflowHidden.asObservable();
	}

	changeAlarmHistoryStatus(alarmId: string, status: string) {
		this.alarmHistory.forEach((alarm: Alarm) => {
			if (alarm.id === alarmId) {
				alarm.status = status;
			}
		});
		this.alarmHistoryChangeListener.next(this.alarmHistory);
	}

	getAlarmHistoryChangeListener() {
		return this.alarmHistoryChangeListener.asObservable();
	}

	async fetchAlarmHistory(alarmOption: any) {
		try {
			const response = await lastValueFrom(this.http.get<any>(ApiConstants.URL_ALARMS + '/branches/' + this.selectedAlarm.branch.id, { params: { ...alarmOption } }));
			if (response.success === true) {
				this.alarmCount = response.totalcount;
				this.alarmHistory = response.data;
				this.alarmHistoryChangeListener.next(this.alarmHistory);
			}
		} catch (err) {
		}
	}

	changeStatusAlarmNotification(data: any, newStatus?: string) {
		this.alarmStatus = newStatus || data.status;
		data.status = newStatus || data.status;
		this.changeAlarmSelectedState.next(data);
		this.updateInsightsDateRange();
	}

	getChangeAlarmStatusNotification() {
		return this.changeAlarmSelectedState.asObservable();
	}

	applyRicUpdates(data: any) {
		this.ricStatusSub.next(data);
		this.updateInsightsDateRange();
	}

	getRicUpdates() {
		return this.ricStatusSub.asObservable();
	}

	getAlarmStatus() {
		return this.alarmStatus;
	}

	Logout(data: any) {
		let currentToken = this.authenticationService.token;
		if (currentToken === data.session_token) {
			this.dialog.closeAll();
			this.authenticationService.logout();
		}
	}

	async updateMap() {
		await this.branchService.getMapBranches2();
	}

	drawZone(zone: any, sensor: string, session_token: string) {
		const body = { 'zone': zone, 'sensor': sensor, 'session_token': session_token };
		return lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ADMIN_ZONE_DRAW, body));
	}

	// get list of alarms types
	async getAlarmsTypes(branchId: string) {
		const response = await lastValueFrom(this.http.get<any>(ApiConstants.URL_BRANCH_ALARM_TYPES + branchId));
		return response;
	}

	// get list of all severity types
	async getSeverityTypes() {
		const response = await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_SEVERITY_TYPES));
		if (response.success === true) {
			return response.data;
		}
	}

	// Add Alarm
	addAlarm(branchId: string, type: string, severity: string, info: string) {
		const body = { 'branchId': branchId, 'type': type, 'severity': severity, 'info': info };
		return lastValueFrom(this.http.post<ApiResponse>(ApiConstants.URL_ADD_ALARMS, body));
	}

	private async checkAssigned(alarmId: string) {
		await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS + '/' + alarmId + ApiConstants.URL_CHECK_ASSIGNED));
	}
	public async checkSelectedAlarmAssigned() {
		await lastValueFrom(this.http.get<ApiResponse>(ApiConstants.URL_ALARMS + '/' + this.selectedAlarm.id + ApiConstants.URL_CHECK_ASSIGNED));
	}

	onNewActionHistoryEvent(data: any) {
		if (!this.selectedAlarm) {
			return;
		}
		let alarmId = data.alarmId || data[0].alarmId;
		let alarmsListLength = this.alarmList.length;
		if (this.selectedAlarm.id === alarmId) {
			if (data.type === "assign" && alarmsListLength === 1) {
				this.onAlarmSelectedListener.next(this.selectedAlarm);
			}
			else {
				this.workflowService.addActionHistory(data);
			}
		}

	}

	onChatMessageNotification(data: any) {
		this.newNotification.next(data);
	}

	async onNewChatNotification(data: any) {
		if (data && data.notification) {
			let systemUsers = await this.authenticationService.getSystemUsers();
			// Extract fromUserId from the notification details
			const fromUserId = data.notification.details.fromUserId;
			// Find the user from the systemUsers array that matches fromUserId
			const matchedUser = systemUsers.find(user => user._id === fromUserId);
			// Check if the matched user has the role 'executive'
			if (matchedUser && matchedUser.role.includes('executive') && !data.notification.details.roomPurposeId) {
				this.newNotification.next(data);
			} else {
				this.chatService.seeNotifications(data.notification._id);
			}
		}
	}

	notificationListner() {
		return this.newNotification.asObservable();
	}

	onNotAllowedAction(data: any) {
		// console.log(data);
	}

	// Sending trigger to question component to handle "NotAllowedAction"
	socketType_Observable(): Observable<any> {
		return this.socketTypeSubject.asObservable();
	}

	async acknowledgeAlarm(alarm: Alarm) {
		let URL = ApiConstants.URL_ALARMS + '/' + alarm.id + '/setAcknowledge';
		const response = await lastValueFrom(this.http.post<ApiResponse>(URL, null));
		if (response && response.success === true) {
			// set alarm status as Acknowledged in Alarms list, Alarm details and Alarm History
			alarm.actionTime = {
				...alarm.actionTime,
				acknowledged: Date.now()
			};
			this.changeStatusAlarmNotification(alarm, AlarmStatus.ACKNOWLEDGED);
			this.changeAlarmHistoryStatus(alarm.id, AlarmStatus.ACKNOWLEDGED);
		}
		return response;
	}

	async getAlarmRelatedUsers(alarmId: string) {
		const url = ApiConstants.URL_ALARM + "/" + alarmId + '/users';
		let response = await lastValueFrom(this.http.get<ApiResponse>(url));
		if (response && response.success === true) {
			return response.data;
		} else {
			return [];
		}
	}

	setChatBotSelected(selected: boolean) {
		this.chatBotSelectedSubject.next(selected);
	}

	getChatBotSelectedListener() {
		return this.chatBotSelectedSubject.asObservable();
	}

}
