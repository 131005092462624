import { Component, HostListener, AfterViewInit, AfterViewChecked, ChangeDetectorRef, OnInit, ViewChild } from '@angular/core';
import { InsightsService } from '../services/insights.service';
import { Subscription } from 'rxjs';
import { Utility } from '../utils/app-utils';
import { DatePickerComponent } from '../utils/date-picker/date-picker.component';

interface severityData {
  label: string;
  value: number;
}

interface incidentsData {
  label: string;
  key: string;
  value: number;
}

interface rangeData {
  label: string;
  value: string;
}

declare var $: any;

@Component({
  selector: 'app-right-section',
  templateUrl: './right-section.component.html',
  styleUrls: ['./right-section.component.scss'],
})

export class RightSectionComponent implements AfterViewInit, AfterViewChecked, OnInit {
  startDate: number;
  endDate: number;
  isScreenSmall: boolean = false;
  isScreenHeightSmall: boolean = false;
  selectedTab: string = 'overview';
  title: string = 'General';
  subTitle: string = 'Overview';
  severityData: severityData[] = [];
  selectedDateRange: any;
  selectedRange: string = 'week';
  dateRange: { startDate: string, endDate: string } | null = null;
  resetDatePicker: boolean = false;

  overviewMinHeight: number = 0;
  alarmMinHeight: number = 0;
  operatorMinHeight: number = 0;
  timeOfTheDayMinHeight: number = 0;
  realTimeMinHeight: number = 0;
  private dateRangeSubscription: Subscription;

  siteInsightData = [
    { label: 'All Sites', value: 0 },
    { label: 'Active Sites', value: 0 },
    { label: 'Inactive Sites', value: 0 },
  ];

  rangeData: rangeData[] = [
    {
      label: '--',
      value: '',
    },
    {
      label: 'Day',
      value: 'day',
    },
    {
      label: 'Week',
      value: 'week',
    },
    {
      label: 'Month',
      value: 'month',
    },
  ]

  incidentsData: incidentsData[] = [
    {
      label: 'Handling',
      key: 'handling',
      value: 0
    },
    {
      label: 'Automation',
      key: 'automation',
      value: 0
    },
    {
      label: 'Escalated',
      key: 'escalated',
      value: 0
    },
    {
      label: 'False',
      key: 'false',
      value: 0
    },
  ];

  constructor(private cdr: ChangeDetectorRef, public insightsService: InsightsService) {
    this.checkScreenWidth();
  }

  async ngOnInit() {
    await this.handleRangeClick(this.selectedRange);
    await this.getInsights();
    this.listenToDateRangeChanges();
  }

  async getInsights() {
    let res = await this.insightsService.getSitesInsights();
    if (res && res.success && res.data && res.data.results) {
      this.updateSiteInsightData(res.data.results);
    } else {
      this.resetSiteInsightData();
    }
  }

  async getLiveAlarmsData() {
    let res = await this.insightsService.getLiveAlarms();
    if (res && res.success && res.data && res.data.results) {
      this.incidentsData.forEach(incident => {
        incident.value = res.data.results[incident.key] ?? 0;
      });
    } else {
      this.incidentsData.forEach(incident => {
        incident.value = 0;
      });
    }
  }

  listenToDateRangeChanges() {
    this.dateRangeSubscription = this.insightsService.currentDateRange.subscribe(async (dateRange) => {
      this.startDate = dateRange.startDate;
      this.endDate = dateRange.endDate;
      await this.getLiveAlarmsData();
      await this.listAlarmsBySeverity(this.startDate, this.endDate);
    });
  }

  async listAlarmsBySeverity(startDate?: number, endDate?: number) {
    const res = await this.insightsService.getAlarmsBySeverity(startDate, endDate);
    if (res && res.success && res.data && res.data.results) {
      this.severityData = res.data.results.map(item => {
        return {
          label: item.severity,
          value: item.currentPeriodAlarmsCount,
        };
      });
    } else {
      this.severityData = [];
    }
  }

  private updateSiteInsightData(data: { connected: number; disconnected: number; total: number }) {
    this.siteInsightData = [
      { label: 'All Sites', value: data.total },
      { label: 'Active Sites', value: data.connected },
      { label: 'Inactive Sites', value: data.disconnected },
    ];
  }

  private resetSiteInsightData() {
    // Set all values to zero if data fetching fails or data format is incorrect
    this.siteInsightData = [
      { label: 'All Sites', value: 0 },
      { label: 'Active Sites', value: 0 },
      { label: 'Inactive Sites', value: 0 },
    ];
  }

  @HostListener('window:resize', ['$event'])

  onResize() {
    this.checkScreenWidth();
  }

  ngAfterViewInit() {
    this.checkScreenWidth();
    this.initializeCarousels();
    this.cdr.detectChanges();
  }

  ngAfterViewChecked() {
    this.checkScreenWidth();
    this.initializeCarousels();
    this.cdr.detectChanges();
  }

  initializeCarousels(): void {
    if (this.selectedTab === 'overview' && $('#top-sites-alarm').length) {
      $('#top-sites-alarm').carousel({ wrap: true });
    }

    if (this.selectedTab === 'alarm' && $('#false-rate-alarms').length) {
      $('#false-rate-alarms').carousel({ wrap: true });
    }

    if (this.selectedTab === 'operator' && $('#operator-activity-tab').length) {
      $('#operator-activity-tab').carousel({ wrap: true });
    }
  }

  checkScreenWidth() {
    this.isScreenSmall = window.innerWidth < 1800;
    this.isScreenHeightSmall =
      window.innerHeight < 900 || window.innerWidth < 1300;
    this.overviewMinHeight = window.innerHeight >= 1300 ? 31.2 : window.innerHeight >= 1080 ? 31.8 : window.innerHeight >= 1024 ? 30.5 : 33;
    this.alarmMinHeight = window.innerHeight >= 1300 ? 33 : window.innerHeight >= 1080 ? 34 : 30.5;
    this.operatorMinHeight = window.innerHeight >= 1300 ? 31 : window.innerHeight >= 1080 ? 34 : window.innerHeight >= 1024 ? 33.8 : 35.5;
    this.timeOfTheDayMinHeight = window.innerHeight >= 1300 ? 26.5 : window.innerHeight >= 1080 ? 27.5 : window.innerHeight >= 1024 ? 23.5 : window.innerHeight >= 934 ? 29.5 : 27;
    this.realTimeMinHeight = window.innerHeight >= 1300 ? 24 : window.innerHeight >= 1080 ? 25 : window.innerHeight >= 1024 ? 22.5 : window.innerHeight >= 934 ? 28 : 27;
  }

  onTabSelected(tab: string) {
    this.selectedTab = tab;
    this.title =
      tab === 'overview'
        ? 'General'
        : tab === 'alarm'
          ? 'Alarm'
          : tab === 'operator'
            ? 'Operator'
            : tab === 'detailed'
              ? 'Detailed'
              : '';
    this.subTitle =
      tab === 'overview'
        ? 'System'
        : tab === 'alarm'
          ? 'Alarm'
          : tab === 'operator'
            ? 'Operator'
            : tab === 'detailed'
              ? 'Detailed'
              : '';
  }

  async handleRangeClick(range: string) {
    if (range === '') {
      this.startDate = undefined;
      this.endDate = undefined;
      this.insightsService.updateDateRange(this.startDate, this.endDate);
      return;
    }

    const now = new Date();
    let startDate: Date;

    switch (range) {
      case 'day':
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case 'week':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case 'month':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds());
        break;
      default:
        return;
    }

    this.startDate = startDate.getTime();
    this.endDate = now.getTime();
    this.selectedRange = range;
    this.dateRange = null; // Reset date-picker when a range is selected
    if (this.datePicker) {
      this.datePicker.resetDates(); // Reset the date picker
    }
    this.insightsService.updateDateRange(this.startDate, this.endDate);
  }

  onRangeReset() {
    this.selectedRange = '';
  }

  async handleDateRangeSelected(event: { startDate: string, endDate: string }) {
    this.dateRange = event;
    this.selectedRange = ''; // Reset selectedRange when a date is picked
    this.startDate = new Date(event.startDate).getTime();
    this.endDate = new Date(event.endDate).getTime();
    this.selectedRange = '';
    this.insightsService.updateDateRange(this.startDate, this.endDate);
    this.selectedDateRange = event;
  }

  updateDateRange(dateRange: any) {
    this.selectedDateRange = dateRange;
  }

  ngOnDestroy() {
    Utility.Unsubscribe(this.dateRangeSubscription);
  }

  @ViewChild(DatePickerComponent) datePicker: DatePickerComponent;

}
